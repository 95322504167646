'use client';  // Declaramos este componente como cliente

import { SessionProvider } from "next-auth/react";
import { ToastContainer } from "react-toastify";
import { AlertContextProvider } from "@/contexts/AlertContext";
import CommonContextProvider from "@/contexts/CommonContext";
import { Modal } from "@/components/common/Modal";
import TranslationsProvider from "@/components/Providers/TranslationsProvider";
import { ReactNode } from "react";
import { PageLoadingProvider } from "@/contexts/PageLoading";

interface ClientProvidersProps {
    children: ReactNode;
    session: any;  // Puedes ajustar el tipo de `session` si es conocido
    locale: string;
    resources: Record<string, any>;
}

export default function ClientProviders({
    children,
    session,
    locale,
    resources,
}: ClientProvidersProps) {

    return (
        <SessionProvider session={session}>
            <TranslationsProvider
                namespaces={['pos', 'auth', 'calendar', 'common', 'companies', 'dashboard', 'goals', 'locations', 'sales', 'settings']}
                locale={locale}
                resources={resources}
            >
                <AlertContextProvider>
                    <CommonContextProvider>
                        <PageLoadingProvider>
                            <Modal />
                            <ToastContainer />
                            {children}
                        </PageLoadingProvider>
                    </CommonContextProvider>
                </AlertContextProvider>
            </TranslationsProvider>
        </SessionProvider>
    );
}
// contexts/PageLoadingContext.tsx
import { useRouter } from 'next/navigation';
import React, { createContext, useContext, useState, useCallback } from 'react';

interface PageLoadingContextProps {
    isPageLoading: boolean;
    navigateWithLoading: (url: string) => void;
}

const PageLoadingContext = createContext<PageLoadingContextProps | undefined>(undefined);

export const PageLoadingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const router = useRouter();
    const [isPageLoading, setIsPageLoading] = useState(false);


    const navigateWithLoading = useCallback(async (url: string) => {
        setIsPageLoading(true); // Activa el loader
    
        const hideLoader = () => setIsPageLoading(false); // Función para ocultar el loader
    
        try {
          // Navega a la nueva ruta y espera un breve retraso para mostrar el loader
          await Promise.all([
            router.push(url), 
            new Promise(resolve => setTimeout(resolve, 300)) // Retraso de 300ms para asegurar que el loader sea visible
          ]);
        } finally {
          hideLoader(); // Desactiva el loader
        }
      }, [router]);

    return (
        <PageLoadingContext.Provider value={{ isPageLoading, navigateWithLoading }}>
            {children}
        </PageLoadingContext.Provider>
    );
};

export const usePageLoading = () => {
    const context = useContext(PageLoadingContext);
    if (!context) {
        throw new Error('usePageLoading must be used within a PageLoadingProvider');
    }
    return context;
};